import type { Plugin } from 'vue'
import type { Locale } from 'vue-i18n'
import messages from '@intlify/unplugin-vue-i18n/messages'
import { createI18n } from 'vue-i18n'

const datetimeFormats = {
  en: {
    'full': {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: false,
    },
    'date': {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
    },
    'date-short': {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    },
    'datetime': {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    },
    'time': {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    },
  },
  fr: {
    'full': {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: false,
    },
    'date': {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
    },
    'date-short': {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    },
    'datetime': {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    },
    'time': {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    },
  },
  nl: {
    'full': {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: false,
    },
    'date': {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
    },
    'date-short': {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    },
    'datetime': {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    },
    'time': {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    },
  },
} as const

const numberFormats = {
  en: {
    currency: {
      style: 'currency',
      currency: 'EUR',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  fr: {
    currency: {
      style: 'currency',
      currency: 'EUR',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  nl: {
    currency: {
      style: 'currency',
      currency: 'EUR',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
} as const

// Import i18n resources
// https://vitejs.dev/guide/features.html#glob-import
//
// Don't need this? Try vitesse-lite: https://github.com/antfu/vitesse-lite
const i18n = createI18n({
  legacy: false,
  locale: document?.querySelector('html')?.getAttribute('lang') ?? 'en',
  fallbackLocale: 'en',
  datetimeFormats,
  numberFormats,
  messages,
})

export function setI18nLanguage(lang: Locale) {
  i18n.global.locale.value = lang as any
  if (typeof document !== 'undefined') {
    document.querySelector('html')?.setAttribute('lang', lang)
  }
  fetch(route('v2.api.language.switch-locale', { locale: lang }))
  return lang
}

export function getLocaleIcon(lang: Locale): string {
  return matchDefault(lang, {
    en: 'i-flag-gb-4x3',
    fr: 'i-flag-fr-4x3',
    nl: 'i-flag-nl-4x3',
  }, '')
}

const install: Plugin & { global: typeof i18n.global } = function installI18n(app) {
  app.use(i18n)
}

install.global = i18n.global
export default install
