import type { DefineComponent } from 'vue'

import i18n from '@/plugins/i18n'
import pinia from '@/plugins/pinia'
import { createInertiaApp } from '@inertiajs/vue3'
import { createApp, h } from 'vue'
import { ZiggyVue } from 'ziggy-js'
import './bootstrap'
import '@unocss/reset/tailwind.css'
import 'virtual:uno.css'
import '../css/v2.css'

createInertiaApp({
  title: title => `${title} - Art of Fiber`,
  resolve: async (name) => {
    const pages = await import.meta.glob('./pages/**/*.vue')
    if (!pages[`./pages/${name}.vue`]) {
      throw new Error(`Page not found: ./pages/${name}.vue`)
    }

    const page = (await pages[`./pages/${name}.vue`]()) as DefineComponent
    const root = name.split('/')[0]

    if (!page.default.layout) {
      page.default.layout = (await matchDefault(
        root,
        {
          Guest: () => import('@/layouts/GuestLayout.vue'),
          Storefront: () => import('@/layouts/StorefrontLayout.vue'),
        },
        () => import('@/layouts/AppLayout.vue'),
      )).default
    }

    return page
    // return resolvePageComponent(`./pages/${name}.vue`, import.meta.glob<DefineComponent>('./pages/**/*.vue'));
  },
  setup({ el, App, props, plugin }) {
    el.classList.add('flex', 'flex-col', 'flex-1', 'min-w-0')
    createApp({ render: () => h(App, props) })
      .use(plugin)
      .use(pinia)
      .use(i18n)
      .use(ZiggyVue)
      .mount(el)
  },
  progress: false,
})
