export function match<
  TValue extends string | number = string,
  TReturnValue = unknown,
>(
  value: TValue,
  lookup: Record<TValue, TReturnValue | ((...args: any[]) => TReturnValue)>,
  ...args: any[]
): TReturnValue {
  if (value in lookup) {
    const returnValue = lookup[value]
    return typeof returnValue === 'function'
      ? returnValue(...args)
      : returnValue
  }

  const error = new Error(
    `Tried to handle "${value}" but there is no handler defined. Only defined handlers are: ${Object.keys(
      lookup,
    )
      .map(key => `"${key}"`)
      .join(', ')}.`,
  )
  if (Error.captureStackTrace) {
    Error.captureStackTrace(error, match)
  }
  throw error
}

export function matchDefault<
  TValue extends string | number = string,
  TReturnValue = unknown,
>(
  value: TValue,
  lookup: Partial<
    Record<TValue, TReturnValue | ((...args: any[]) => TReturnValue)>
  >,
  defaultValue: ((...args: any[]) => TReturnValue) | TReturnValue,
  ...args: any[]
): TReturnValue {
  if (value in lookup) {
    const returnValue = lookup[value]
    return typeof returnValue === 'function'
      ? returnValue(...args)
      : returnValue
  }

  if (typeof defaultValue === 'undefined' || defaultValue === null) {
    const error = new Error(
      `Tried to handle "${value}" but there is no handler defined. Only defined handlers are: ${Object.keys(
        lookup,
      )
        .map(key => `"${key}"`)
        .join(', ')}.`,
    )
    if (Error.captureStackTrace) {
      Error.captureStackTrace(error, match)
    }
    throw error
  }

  // TODO: Why please?!
  return typeof defaultValue === 'function' // @ts-expect-error return default
    ? defaultValue(...args)
    : defaultValue
}
